@import '../../styles/fonts.scss';

.header {
  top: 0;
  width: 100%;
  z-index: 10000;
  position: fixed;
  background: #20272f;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);

  &__wrapper {
    display: flex;
    align-items: center;
    padding: 24px 32px !important;
    justify-content: space-between;
  }

  .page__logo {
    width: 80px;
    height: 80px;
    border-radius: 8px;

    @media (max-width: 760px) {
      width: 40px;
      height: 40px;
      border-radius: 4px;
    }
  }

  .menu {
    gap: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .menu__item {
      font-size: 24px;
      cursor: pointer;
      line-height: 28px;
      font-weight: 200;
      font-family: $robotoFontFamily;
      color: white;
      transition: color 0.5s;

      & a {
        color: inherit;
      }
    }

    .menu__item__active {
      color: #6b7c58;
    }

    @media (max-width: 1024px) {
      gap: 20px;
      & .menu__item {
        font-size: 20px;
      }
    }

    @media (max-width: 750px) {
      display: none;
    }
  }

  & .burger__menu {
    display: none;

    @media (max-width: 750px) {
      display: block;
    }
  }
}

.overlay {
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: none;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);

  &.show {
    display: block;
  }

  transition: all 0.5s;
}

.sidebar {
  top: 0;
  right: 0;
  width: 70%;
  height: 100%;
  z-index: 1000000;
  display: none;
  position: fixed;
  background: #20272f;
  align-items: flex-start;
  padding: 0 20px;
  flex-direction: column;
  justify-content: flex-start;
  flex-direction: column;

  &.show {
    transform: translateX(0);
  }

  &.hide {
    transform: translateX(100%);
  }

  transition: transform 0.5s;

  & .menu__item {
    margin-top: 20px;
    font-size: 24px;
    cursor: pointer;
    line-height: 28px;
    font-weight: 200;
    color: rgba(255, 255, 255, 0.6);
    font-family: $robotoFontFamily;

    transition: color 0.5s;

    & a {
      color: inherit;
    }
  }

  & .menu__item__active {
    color: #6b7c58;
  }

  @media (max-width: 750px) {
    display: flex;
  }

  & .sidebar__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;

    padding: 20px 0;

    & .language__changer {
      color: #fff;
      width: 110px;
      height: 40px;
      display: flex;
      font-size: 18px;
      font-weight: 400;
      line-height: 21px;
      background: #6b7c58;
      align-items: center;
      border-radius: 5px;
      justify-content: center;
      font-family: $robotoFontFamily;
    }

    & .sidebar__close {
      cursor: pointer;
      width: 28px;
      height: 28px;
    }
  }
}
