@import '../../styles/fonts.scss';

.container {
  background-color: #20272f;
  color: #fff;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.footer {
  gap: 50px;
  display: flex;
  align-items: flex-start;
  padding-top: 102px;
  padding-bottom: 102px;
  justify-content: space-between;

  @media (max-width: 1024px) {
    gap: 30px;
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse;
  }

  & a {
    color: #ffffff;
    text-decoration: none;
  }

  &__title {
    text-align: center;
    line-height: 60px;
    font-size: 40px;
    font-weight: 500;
    font-family: $poppinsFontFamily;

    @media (max-width: 1024px) {
      font-size: 48px;
      line-height: 56px;
    }

    @media (max-width: 768px) {
      font-size: 36px;
      line-height: 48px;
    }
  }

  &__description {
    text-align: center;
    line-height: 27px;
    font-size: 18px;
    font-weight: 500;
    font-family: $poppinsFontFamily;

    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  &__left {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;

    &_top {
      gap: 30px;
      display: flex;
      flex-direction: column;
    }

    &_copyright {
      text-align: center;
      line-height: 22px;
      font-size: 18px;
      font-weight: 400;
      margin: 50px auto 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
      font-family: $poppinsFontFamily;
    }
  }

  &__center {
    gap: 25px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    &_img {
      width: 100%;
      max-width: 390px;
      max-height: 390px;
      border-radius: 16px;
    }

    &__socials {
      display: flex;
      width: 100%;
      max-width: 390px;
      align-items: center;
      justify-content: space-evenly;

      svg {
        width: 44px;
        height: 44px;

        path {
          fill: #6b7c58;
        }

        @media (max-width: 1024px) {
          width: 36px;
          height: 36px;
        }
      }
    }
  }

  &__right {
    flex: 1;

    &_top {
      gap: 30px;
      display: flex;
      margin-bottom: 20px;
      flex-direction: column;
    }

    &_bottom {
      gap: 8px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      @media (min-width: 625px) and (max-width: 1024px) {
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
      }

      @media (max-width: 625px) {
        align-items: center;
        justify-content: center;
      }

      &_item {
        gap: 10px;
        color: #fff;
        display: flex;
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        align-items: center;
        justify-content: center;
        font-family: $poppinsFontFamily;

        svg {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}
